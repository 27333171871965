<template>
  <button v-if="badge1" class="widgetButton" :class="`${fgColor1} ${bgColor1}`" v-tooltip="title1" v-badge="badge1">
    <div class="widgetButton_lg" :class="``"></div>
    <span class="button_text text-sm md:text-md"><i v-if="icon1" :class="icon1" class="icon-inline fs-large mr-1"></i>{{ title1 }}</span>
  </button>
  <button v-else class="widgetButton" :class="`${fgColor1} ${bgColor1}`" v-tooltip="title1">
    <div class="widgetButton_lg" :class="``"></div>
    <span class="button_text text-sm md:text-md"><i v-if="icon1" :class="icon1" class="icon-inline fs-large mr-1"></i>{{ title1 }}</span>
  </button>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "buttonEnat",
  setup() {},
  components: {
  },
  props: {
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    fgColor: {
      type: String,
      required: false
    },
    bgColor: {
      type: null,
      required: false
    },
    badge: {
      type: null,
      required: false
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  watch: {

  },
  computed: {
    title1: function () {
      if (this.title) return this.title;
      return '';
    },
    subtitle1: function () {
      if (this.subtitle) return this.subtitle;
      return '';
    },
    icon1: function () {
      if (this.icon) {
        return this.icon;
      }
      return '';
    },
    fgColor1: function () {
      if (this.fgColor) {
        return `fg-${this.fgColor}`;
      }
      return '';
    },
    bgColor1: function () {
      if (this.bgColor) {
        return `bg-${this.bgColor}`;
      }
      return '';
    },
    badge1: function () {
      if (this.badge) {
        return this.badge.toString();
      }
      return null;
    },
  },
  mounted() {
  },
  methods: {
  },
});
</script>

<style lang="scss" scoped>
.widgetButton {
  font-family: "SystemFont", Helvetica, Arial, sans-serif;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  text-transform: uppercase;
  border: none;
  padding: 8px 10px;
  border-radius: 6px;
  // padding: 8px 10px;
  background: #424242;
  color: white;
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, .1) inset,
    0 0 0 1px rgba(0, 0, 0, .7),
    0 -1px 0 0 rgba(0, 0, 0, .7) inset,
    0 1px 2px 1px rgba(0, 0, 0, .6);
  cursor: pointer;
  font-weight: 600;
  transition: background 0.2s ease;
}

.widgetButton:hover, .widgetButton:focus, .widgetButton:focus-visible {
  // background-image: linear-gradient(to bottom right, transparent, #fffb00) !important;
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, .05) inset,
    0 0 0 1px rgba(0, 0, 0, .7),
    0 -1px 0 0 rgba(0, 0, 0, .7) inset,
    0 1px 2px 1px rgba(0, 0, 0, .6);
  border: none;
  outline: none;
}

.widgetButton:active {
  background: #181818;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .7) inset,
    0 1px 0 0 rgba(255, 255, 255, .05),
    0 1px 2px 1px rgba(0, 0, 0, .6) inset;
}

.widgetButton:hover {
  background: linear-gradient(15deg, #1e1e1e, #272727) !important;
  color: #e4e4e4 !important;
}

</style>