<template>
  <div class="info flex flex-column">
      <div class="info flex flex-column fg-lime">
          <transition name="valchange" mode="out-in">
              <h1 :key="value1" :class="`hidden md:inline-block mb-0 mt-0 ${showPointer}`" @click="showChart('value1')">
                  {{ value1 }}<span class="text-sm" style="vertical-align: super">{{ unit1 }}</span>
              </h1>
          </transition>
          <h4 :key="value1" :class="`inline-block md:hidden mb-0 mt-1 ${showPointer}`" @click="showChart('value1')">
              {{ value1 }}<span class="text-sm" style="vertical-align: super">{{ unit1 }}</span>
          </h4>
      </div>
      <div class="flex flex-row ribbon mt-1">
          <div class="flex flex-column justify-content-center">
              <span :class="fgColor1" class="hidden md:block subtext mt-1">{{ departmentLabel }} ⚡ {{ moduleData.metadata.label }}</span>
              <span :class="fgColor1" class="md:hidden text-small mt-1">{{ departmentLabel }}:{{ moduleData.metadata.label }}</span>
              <span :class="fgColor1" class="hidden md:block subtext mt-1">{{ moduleData.metadata.description }}</span>
              <span :class="fgColor1" class="md:hidden text-small mt-1">{{ moduleData.metadata.description }}</span>
          </div>
          <div class="flex flex-column justify-content-center align-items-center p-1 ml-1">
            <div class="md:hidden ml-1" v-tooltip="`Abteilung <b>${departmentLabel}</b>`">{{ departmentKey }}</div>
            <div class="hidden md:block key-txt ml-1" v-tooltip="`Abteilung <b>${departmentLabel}</b>`">{{ departmentKey }}</div>
              <!-- <div class="flex align-items-center justify-content-center card bg-bespin shadow-3 w-max h-full p-1" v-tooltip="departmentLabel"> -->
              <!-- <div class="input-container" v-tooltip="`Abteilung <b>${departmentLabel}</b>`"> -->
                <!-- <i class="icon-inline" :class="icon1"></i> -->
                <!-- <h4 class="hidden md:block title">{{ departmentKey }}</h4>
                <h6 class="md:hidden title">{{ departmentKey }}</h6> -->
              <!-- </div>  -->
          </div>
      </div>
  </div>
  <dialog-chart-single :icon="value.icon" :node="value" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from 'vuex';
import store from '@/store';
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
// import widgetGuiValue from '@/components/fragments/widgetGuiValue.vue';

export default defineComponent({
  name: "widgetHeader",
  emits: ['showChart'],
  setup() {},
  components: {
    // widgetGuiValue,
    dialogChartSingle,
  },
  props: {
    value: {
      type: null,
      required: true
    },
    moduleData: {
      type: Object,
      required: true
    },
    fgColor: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      loading: false,
      showChart1: false,
    }
  },
  watch: {

  },
  computed: {
    ...mapGetters({
        editMode: 'getEditMode',
        getNodeWidget: 'getNodeWidget',
        getOneDepartment: 'types/getOneDepartment',
        getOneCategory: 'types/getOneCategory',
        getStyleColors: 'getStyleColors',
        getStylePatterns: 'getStylePatterns',
        getModules: 'opcua/getModules',
        getModule: 'opcua/getModule',
        getNode: 'opcua/getNode',
        getModuleFetched: 'opcua/getModuleFetched',
        getSynoptoCode: 'types/getSynoptoCode',
        isMaster: 'auth/isMaster',
    }),
    value1: function () {
      if (typeof(this.value) === "object") return this.value.value;
      else if (typeof(this.value) === "string") return this.value;
      return '';
    },
    unit1: function () {
      if (typeof(this.value) === "object") {
        if (this.value.unit.includes("#")) return '';
        return this.value.unit;
      }
      return '';
    },
    icon1: function () {
      return "fi fi-rr-home";
    },
    fgColor1: function () {
      if (this.fgColor) {
        return `${this.fgColor}`;
      }
      return '';
    },
    department: function () {
      if (this.moduleData.metadata.department) return this.moduleData.metadata.department;
      else return "";
    },
    label: function () {
      if (this.moduleData.metadata.label) return this.moduleData.metadata.label;
      else return "";
    },
    description: function () {
      if (this.moduleData.metadata.description) return this.moduleData.metadata.description;
      else return "";
    },
    departmentKey: function () {
      return this.moduleDepartment().key;
    },
    departmentLabel: function () {
      return this.moduleDepartment().label;
    },
    isRecord() {
      if (typeof(this.value) === "object") {
        if (this.value.record === "true") return true;
      }
      return false;
    },
    showPointer() {
      if (this.isRecord) return "cursor-pointer";
      else return "";
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    moduleDepartment: function () {
        if (this.moduleData !== null) {
            if (this.moduleData.metadata.department !== null) {
                return this.getOneDepartment(this.moduleData.metadata.department);
            }
        }
        return { key: store.getters.getNoval, label: store.getters.getNoval };
    },
    moduleCategory: function () {
        if (this.moduleData !== null) {
            if (this.moduleData.metadata.category !== null) {
                return this.getOneDepartment(this.moduleData.metadata.category);
            }
        }
        return { key: store.getters.getNoval, label: store.getters.getNoval };
    },
    showChart(value) {
      if (this.isRecord) {
        if (value === 'value1') this.showChart1 = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  position: relative;
  align-items: center;
  color: #f9bf45;
  font-size: 1.2rem;
  // background: linear-gradient(45deg, rgba(168, 73, 122, 0.8), rgba(168, 73, 122, 0.7));
  padding: 8px 12px;
  gap: 5px;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(8, 8, 8, .5) inset,
    0 1px 0 0 rgba(252, 250, 242, .07),
    0 1px 2px 1px rgba(8, 8, 8, .5) inset;
}

.input-container .bash-text {
  font-size: .8rem;
  color: white;
}

.input-container .bash-text .user {
  color: #E879F9;
}

.input-container .bash-text .vm {
  color: #2DD4BF;
}

.input-container .bash-text .char {
  color: #A78BFA;
}

.input-container .bash-text .value {
  background-color: transparent;
  border: none;
  outline: none;
  color: yellow;
}

.input-container .icon-inline {
  position: absolute;
  font-size: 3.4rem;
  right: -20px;
  color: #b481bb;
  opacity: 0.2;
}

.input-container .title {
  margin: 0;
  padding: 0;
  z-index: 1;
}
.ribbon{
  position:relative;
  padding: 0 0.4em;
  margin: 0 -0.550em 0.335em 0;
  line-height: 1.475em;
  color: #e6e2c8;
  border-radius: 0.456em 0 0 1.156em;
  background: linear-gradient(45deg, rgba(15, 23, 42, 0.5), rgba(25, 39, 71, 0.5));
  box-shadow: -1px 2px 2px rgba(0,0,0,0.5);
}

.ribbon:before{
  width: 0.469em;
  height: 100%;
  top:0;
  right: -0.469em;
  background: rgb(179, 176, 0);
  border-radius: 0;
  box-shadow: -1px 1px 1px rgba(0,0,0,0.5);
}
.ribbon2:after{
  width: 0.469em;
  height: 100%;
  top:0;
  left: -0.469em;
  background: rgba(231, 219, 219, 0.411);
  border-radius: 0.213em 0 0 0.213em;
  box-shadow: -1px 2px 1px rgba(0,0,0,0.5);
}

.ribbon:before, .ribbon:after{
  position:absolute;
  content: '';
  display: block;
}

.key-txt {
  font-size:1.700em;
}


// .ribbon:before{
//   width: 0.469em;
//   height: 100%;
//   padding: 0.438em 0 0 0;
//   top:0;
//   right: -0.469em;
//   background:inherit;
//   border-radius: 0 0.313em 0.313em 0;
// }

// .ribbon:after{
//   width: 0.313em;
//   height: 0.313em;
//   background: rgba(0,0,0,0.35);
//   bottom: -0.313em;
//   right: -0.313em;
//   border-radius: 0 0.313em 0.313em 0;
//   box-shadow: inset -1px 2px 2px rgba(0,0,0,0.3);
// }
</style>